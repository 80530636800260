export class ResponseGrafici {
    anno!: number;
    grafici!: Grafici;
}

export class Grafici {
    esagono!: Grafico;
    matematica!: Grafico;
    radar!: Grafico;
    impegno!: Grafico;
    personalita!: Grafico;
    motivazione!: Grafico;
    italiano!: Grafico;
    'radar-palline'!: Grafico;
    scuola_superiore_scelta?: Grafico;
    scuola_superiore_sensazione!: Grafico;
}

export class Grafico {
    id!: string;
    assi?: Asse[];
}

export class Asse {
    id!: string;
    valore!: any;
    parametri!: { [anno: number]: ParametriGrafici }
}

export class ParametriGrafici {
    min!: number;
    max!: number;
    cutOffLower!: number;
    cutOffUpper!: number;
}