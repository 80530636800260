<header id="header-nav">
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-between align-items-center">
        <div class="logo" (click)="goToDashboard()">
          <img src="../../../assets/images/logo-esteso.svg" alt="FUtuRI">
        </div>
        <nav class="main-menu">
          <ul class="menu">
            <li>
              <a (click)="scrollOnSection('section-percorso')">
                <span class="futuri-icon"><i class="icon-map"></i></span>
                <span class="text">il mio percorso</span>
              </a>
              <div class="parallelepipedo"></div>
            </li>
            <li>
              <a (click)="scrollOnSection('section-risultati')">
                <span class="futuri-icon"><i class="icon-chart-pie"></i></span>
                <span class="text">i miei risultati</span>
              </a>
              <div class="parallelepipedo"></div>
            </li>
            <li *ngIf="auth.user && auth.user.classe && auth.user.classe.grado ? auth.user.classe.grado > 1: false">
              <a (click)="scrollOnSection('section-contenuti')">
                <span class="futuri-icon"><i class="icon-video"></i></span>
                <span class="text">contenuti</span>
              </a>
              <div class="parallelepipedo"></div>
            </li>
          </ul>
        </nav>
        <div class="wrap-info">
          <div class="d-flex justify-content-end align-items-center flex-row">
            <div class="button-notify">
              <button class="header-button" type="button" (click)="opNotifiche.toggle($event)">
                <div *ngIf="newNotify.length>0" class="new-notify"></div>
                <span class="futuri-icon bell" [ngClass]="{'active': newNotify.length>0}"><i
                    class="icon-bell"></i></span>
              </button>
            </div>
            <div class="profile">
              <div class="text">
                <div class="nome">{{auth.user?.name}} {{auth.user?.cognome | slice:0:1}}.</div>
                <div class="ruolo">Studente</div>
              </div>
              <div class="avatar" (click)="op.toggle($event)">
                <img [src]="auth.user?.avatarUrl" [alt]="auth.user?.name" class="avatar-icon">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<p-overlayPanel styleClass="overlay-profile" [appendTo]="'body'" #op>
  <ng-template pTemplate>
    <nav id="settings-nav">
      <ul>
        <li>
          <a (click)="op.hide()" routerLink="/profilo" [routerLinkActive]="['router-link-active']">
            <span><i class="pi pi-cog"></i></span>Settings</a>
        </li>
        <li><a (click)="logout()"><span><i class="pi pi-sign-out"></i></span>Logout</a></li>
      </ul>
    </nav>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel styleClass="overlay-notifiche" [appendTo]="'body'" #opNotifiche
  [style]="{'width': '370px','height':'550px'}" (onHide)="readNotify()">
  <ng-template pTemplate>
    <div id="notifiche-header">
      <h4>Notifiche</h4>
      <div class="wrap-notifiche">
        <div class="nuove">
          <span class="tipologia">Nuove</span>
          <ng-container *ngFor="let notifica of newNotify">
            <ng-container *ngTemplateOutlet="tempNotifica; context: {notifica:notifica}"></ng-container>
          </ng-container>
        </div>
        <div *ngIf="newNotify.length==0" class="no-notifiche">
          <span>Non ci sono nuove notifiche</span>
        </div>
        <div class="precedenti">
          <span class="tipologia">Precedenti</span>
          <ng-container *ngFor="let notifica of oldNotify">
            <ng-container *ngTemplateOutlet="tempNotifica; context: {notifica:notifica}"></ng-container>
          </ng-container>
        </div>
        <div *ngIf="oldNotify.length==0" class="no-notifiche">
          <span>Non ci sono nuove notifiche</span>
        </div>
      </div>
    </div>
    <ng-template #tempNotifica let-notifica="notifica">
      <div class="notifica d-flex justify-content-start align-items-center">
        <div class="wrap-icon">
          <div [class]="getIconByTypeNotify(notifica.type)"></div>
        </div>
        <div class="className">
          <p [innerHTML]="notifica.className"></p>
        </div>
        <div class="wrap-body">
          <div class="title" [innerHTML]="notifica.title[translate.currentLang]"></div>
          <div class="text" [innerHtml]="notifica.text[translate.currentLang]"></div>
        </div>
        <div class="new-notify" *ngIf="notifica.show"></div>
        <span class="icon icon-arrow-right" (click)="goToNotify(notifica); opNotifiche.hide()"></span>
      </div>
    </ng-template>
  </ng-template>
</p-overlayPanel>