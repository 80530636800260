<div id="svolgi-modulo">
  <div class="intestazione">
    <div class="d-flex justify-content-center align-items-center flex-wrap">
      <div class="testo">
        <h3>{{datasource?.nome}}</h3>
        <p [innerHtml]="datasource?.testo"></p>
      </div>
      <div class="image"></div>
    </div>
  </div>
  <ng-container *ngFor="let traccia of datasource?.tracce; let i = index">
    <div class="disclaim">
      {{traccia.testo}}
    </div>
    <div class="questionario">
      <div class="wrap-domande">
        <div class="card-domanda">
          <ng-container *ngFor="let elemento of traccia.elementi">
            <ng-container [ngSwitch]="elemento.tipologia">
              <ng-container *ngSwitchCase="tipologiaDomande.aperta">
                <div class="domanda">
                  {{elemento.testo}}
                </div>
                <div class="risposta">
                  <textarea [id]="elemento.id" [name]="elemento.id!" [(ngModel)]="elemento.risposta" [rows]="5"
                    [cols]="30" pInputTextarea [autoResize]="true" placeholder="Scrivi la tua risposta"></textarea>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="tipologiaDomande.radio">
                <div class="domanda">
                  {{elemento.testo}}
                </div>
                <ng-container *ngFor="let domande of elemento.domande">
                  <div class="questionario-radio">
                    <div class="d-sm-flex justify-content-sm-between align-items-center flex-wrap">
                      <div class="dom">
                        <span class="domanda">{{domande.testo}}</span>
                      </div>
                      <div class="wrap-radio">
                        <div class="d-sm-flex justify-content-sm-between align-items-center">
                          <ng-container *ngFor="let risposta of elemento.risposte">
                            <div class="d-flex flex-sm-column align-items-center input-radio-sv">
                              <label>{{ risposta.testo! }}</label>
                              <p-radioButton [name]="domande.id!" [value]='risposta.valore'
                                [(ngModel)]="domande.risposta">
                              </p-radioButton>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="wrap-button">
    <button pButton pRipple type="submit" label="Salva Modulo" class="p-button" (click)="salvaModulo()"
      [disabled]="!datasource || checkDomande()" style="width:100%; height:41px; margin-bottom: 20px;"></button>
  </div>
</div>
