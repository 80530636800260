import { TypeNotify } from ".";

export class Notifica {
    text?: Text;
    show?: boolean;
    params?: Params;
    date?: string;
    title?: Title;
    className?: string;
    type?: TypeNotify;
}

export class Title {
    [key: string]: string;
};

export class Text {
    [key: string]: string;
}

export class Params {
    [key: string]: string;
}