import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contenuti, FiltroContenuti, Page, Tag } from '../_model';

@Injectable({
  providedIn: 'root'
})

export class ContenutiService {
  constructor(private http: HttpClient) { }

  postFilterContenuti(body: FiltroContenuti, params?: any): Observable<Page<Contenuti>> {
    let url = `${environment.host}/contenuti`;
    let queryParams: any = {};
    if (params) {
      queryParams = params;
    }
    return this.http.post<Page<Contenuti>>(url, body, { params: queryParams });
  }

  getAmbitiAndTipi(): Observable<Tag[]> {
    let url = `${environment.host}/contenuti/ambiti`;
    return this.http.get<Tag[]>(url);
  }

  getScuoleByAmbitoId(ambitoId: string): Observable<Tag[]> {
    let url = `${environment.host}/contenuti/ambiti/${ambitoId}/scuole`;
    return this.http.get<Tag[]>(url);
  }
}
