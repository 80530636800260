import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomandeModuli, ModuloOrientativo, ModuloValutazioneDocente } from '../_model';

@Injectable({
  providedIn: 'root',
})
export class ModuliService {
  constructor(private http: HttpClient) {}

  Moduli(id: string, params?: any): Observable<Array<ModuloOrientativo>> {
    let queryParams: any = {};
    queryParams = params && !!params ? params : null;
    let url = `${environment.host}/studenti/${id}/moduli-studenti`;
    return this.http.get<Array<ModuloOrientativo>>(url, { params: queryParams });
  }

  ValutazioneModuloStudente(id: string): Observable<ModuloValutazioneDocente> {
    let url = `${environment.host}/moduli-valutazioni/${id}`
    return this.http.get<ModuloValutazioneDocente>(url);
  }

  ModuloDiario(id: string): Observable<DomandeModuli> {
    let url = `${environment.host}/moduli-diari/${id}`
    return this.http.get<DomandeModuli>(url);
  }

  DownloadPdf(id: string) {
    let url = `${environment.host}/moduli-valutazioni/${id}/pdf`;
    return this.http.get(url, { responseType: 'text' });
  }

}
