import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DiarioRequest, DomandeModuli, ModuloOrientativo, Questionario, StatusQuestionarioStudente } from '../_model';
import { DomandeQuestionarioNC } from '../_model/DomandeQuestionarioNC';
import { Constants } from '../_utility';

@Injectable({
  providedIn: 'root'
})
export class QuestionariService {
  constructor(private http: HttpClient) { }

  getQuestionariAttivi(tipologia: string, stato: string): Observable<Array<Questionario>> {
    let url = `${environment.host}${Constants.QuestionariService.QUESTIONARI_ATTIVI}`;
    return this.http.get<Array<Questionario>>(url, { params: { 'tipologia': tipologia, 'stato': StatusQuestionarioStudente.aperta } });
  }

  getModuliOrientativi(studenteId: string, params?: any): Observable<Array<ModuloOrientativo>> {
    let url = `${environment.host}/studenti/${studenteId}/moduli-studenti`;
    let queryParams: any = {};
    if (params) {
      queryParams = params;
    }
    return this.http.get<Array<ModuloOrientativo>>(url, { params: queryParams });
  }

  getPreviewQuestionariNonCognitivi(id: string): Observable<DomandeQuestionarioNC> {
    let url = `${environment.host}/questionari/${id}/preview`;
    return this.http.get<DomandeQuestionarioNC>(url);
  }

  saveQuestionarioNonCognitivo(id: string, model: any): Observable<any> {
    let url = `${environment.host}/questionari-studenti/${id}`;
    return this.http.post<Array<any>>(url, model);
  }

  getPreviewModuliOrientativi(moduloStudenteId: string): Observable<DomandeModuli> {
    let url = `${environment.host}/moduli-studenti/${moduloStudenteId}/preview-diario`;
    return this.http.get<DomandeModuli>(url);
  }

  // saveModuloOrientativo(studenteId: string, moduloId: string, body:DiarioRequest): Observable<DiarioRequest> {
  //   let url = `${environment.host}/studenti/${studenteId}/moduli/${moduloId}/diario`;
  //   return this.http.post<DiarioRequest>(url, body);
  // }

  saveModuloOrientativo(moduloStudenteId: string, body:DomandeModuli): Observable<DomandeModuli> {
    let url = `${environment.host}/moduli-studenti/${moduloStudenteId}/moduli-diari`;
    return this.http.post<DomandeModuli>(url, body);
  }

  getQuestionarioStudente(questionarioStudenteId: string): Observable<Array<any>> {
    let url = `${environment.host}/questionari-studenti/${questionarioStudenteId}`;
    return this.http.get<Array<any>>(url);
  }

  getQuestionarioStudenteProva(questionarioStudenteId: string): Observable<Array<any>> {
    let url = `${environment.host}/questionari-studenti/${questionarioStudenteId}/prova`;
    return this.http.get<Array<any>>(url);
  }
}
