import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService) { }

  getTerminiECondizioni(): any {
    let lang = this.translate.currentLang;
    let url = `${environment.bucket}/t&c/${lang}/FUtuRI_termini_servizio.pdf`
    return url;
  }

  getPrivacyPolicy(): any {
    let lang = this.translate.currentLang;
    let url = `${environment.futuri.redirect_url}/assets/documents/${lang}/FUtuRI_privacy_policy.pdf`
    return url;
  }
}
