import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpinnerService } from '../../spinner/spinner.service';
import { QuestionarioCognitivoRisposteResponse, Domanda } from '../../../_model';
import { QuestionariService } from '../../../_service';
import { Constants, MyToastrService } from '../../../_utility';

@Component({
  selector: 'app-risultati-questionari-cognitivi',
  templateUrl: './risultati-questionari-cognitivi.component.html',
  styleUrls: ['./risultati-questionari-cognitivi.component.scss']
})
export class RisultatiQuestionariCognitiviComponent implements OnInit {
  @Output() saved = new EventEmitter<boolean>();
  @Input() public id?: string;

  public datasource: QuestionarioCognitivoRisposteResponse<Domanda> = new QuestionarioCognitivoRisposteResponse<Domanda>();


  constructor(
    private questionariService: QuestionariService,
    private spinner: SpinnerService,
    private toastr: MyToastrService,
  ) { }

  ngOnInit(): void {
    let id = this.id;
    if (id) {
      this.getRisposteCognitivo(id);
    }
  }

  private getRisposteCognitivo(questionarioId: string) {
    this.spinner.show();
    this.questionariService.getQuestionarioStudenteProva(questionarioId).subscribe({
      next: (res: any) => {
        this.datasource = res;
        this.spinner.hide();
      },
      error: (err) => {
        this.saved.emit(false);
        this.spinner.hide();
        this.toastr.WARNING(Constants.TITLE_WARNING, 'Errore nel recupero del questionario');
      }
    });
  }

  show(text: string){
    console.log(text);
  }

  getModelByDomanda(domanda:any, risposta: any){
    if ((domanda.tipo == 'G' || 'I' || 'U' || 'P') && risposta.label) {
      return risposta.label;
    }

    if ((domanda.tipo !== 'G' && 'I' && 'U' && 'P') && risposta) {
      return risposta;
    }

    return;
  }

  getModelByDomandaRC(domanda: any, risposta: any) {
    if ((domanda.tipo == 'G' || 'P') && risposta.corretta) {
      return risposta.corretta;
    }

    if ((domanda.tipo == 'I' ) && risposta.label) {
      return risposta.label;
    }

    if ((domanda.tipo !== 'G' && 'I' && 'P' && 'U') && risposta) {
      return risposta;
    }

    return;
  }

}
