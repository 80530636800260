import { Component, HostListener } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SvolgiAnteprimaProvaService } from './_components/svolgi-prova/svolgi-anteprima-prova.service';
import { SvolgiProva } from './_model';
import { AuthService } from './_service';
import { Constants, LocaleService } from './_utility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public getScreenWidth: any;
  public getScreenHeight: any;
  public loading = true;
  public showProva = false;
  public provaId?: string;
  public provaFlag?: string;
  public hideHeader: boolean = false;

  constructor(
    private router: Router,
    private locale: LocaleService,
    private primengConfig: PrimeNGConfig,
    private authService: AuthService,
    private svolgi: SvolgiAnteprimaProvaService
  ) {
    this.locale.init();
    this.authService.user = null;
    this.primengConfig.ripple = true;
    let exclude: string[] = [
      Constants.Routing.ESPRIMI_PREFERENZE.routerLink[0],
      Constants.Routing.COMPETENZE.routerLink[0],
      Constants.Routing.RISULTATO_MODULO.routerLink[0],
    ];
    // Router event per loading page
    this.router.events.subscribe((event: RouterEvent) => {
      if (exclude.includes(location.pathname)) {
        this.hideHeader = true;
      } else {
        this.hideHeader = false;
      }
      this.navigationInterceptor(event);
    });

    // gestione svolgi/anteprima prova questionari non cognitivi
    this.svolgi.provaObservable.subscribe((val: SvolgiProva) => {
      this.showProva = val.show;
      this.provaId = val.id;
      this.provaFlag = val.flag;
    });
  }

  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
}
