import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvolgiProvaComponent } from './svolgi-prova.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SvolgiQuestionarioComponent } from './svolgi-questionario/svolgi-questionario.component';
import { SvolgiModuloComponent } from './svolgi-modulo/svolgi-modulo.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KatexModule } from 'ng-katex';
import { RisultatiQuestionariCognitiviComponent } from './risultati-questionari-cognitivi/risultati-questionari-cognitivi.component';
import { SvolgiCognitivoComponent } from './svolgi-cognitivo/svolgi-cognitivo.component';
import { PaginatorModule } from 'primeng/paginator';
import { PipesModule } from '../../_utility/pipes/pipes.module';

@NgModule({
  declarations: [
    SvolgiProvaComponent,
    SvolgiQuestionarioComponent,
    SvolgiModuloComponent,
    RisultatiQuestionariCognitiviComponent,
    SvolgiCognitivoComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
    RadioButtonModule,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    KatexModule,
    PaginatorModule,
    PipesModule
  ],
  exports: [
    SvolgiProvaComponent,
    SvolgiQuestionarioComponent,
  ]
})
export class SvolgiProvaModule { }
