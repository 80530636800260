import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SvolgiProva } from '../../_model';

@Injectable({
  providedIn: 'root'
})
export class SvolgiAnteprimaProvaService {

  public provaObservable = new Subject<any>();

  constructor() { }

  show(obj: any) {
    this.provaObservable.next(obj);
  }

  hide() {
    let obj: SvolgiProva = new SvolgiProva(false, '', '');
    this.provaObservable.next(obj);
  }

}
