import { ScuolaTag, StatoPreferenza } from "."

export class Preferenza {
    id!: string;
    studenteId!: string;
    studenteNome!: string;
    studenteCognome!: string;
    stato!: StatoPreferenza;
    note?: string;
    scuole!: Array<ScuolaTag>;
}