import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CertificazioneCompetenzeClasse, CertificazioneCompetenzeStudente, ModuloValutazioneDoc } from '../_model';

@Injectable({
  providedIn: 'root'
})
export class CompetenzeService {

  constructor(private http: HttpClient) { }

  getInfoCompetenze(certCompClasseId: string): Observable<CertificazioneCompetenzeClasse> {
    let url = `${environment.host}/certificazioni-competenze-classi/${certCompClasseId}`;
    return this.http.get<CertificazioneCompetenzeClasse>(url);
  }

  getStudentiCompetenze(certCompClasseId: string): Observable<Array<CertificazioneCompetenzeStudente>> {
    let url = `${environment.host}/certificazioni-competenze-classi/${certCompClasseId}/certificazioni-competenze-studenti`;
    return this.http.get<Array<CertificazioneCompetenzeStudente>>(url);
  }

  getCompetenzeStudente(certCompStudenteId: string): Observable<ModuloValutazioneDoc> {
    let url = `${environment.host}/certificazioni-competenze-studenti/${certCompStudenteId}`;
    return this.http.get<ModuloValutazioneDoc>(url);
  }

  downloadPdf(certCompStudenteId: string) {
    let url = `${environment.host}/certificazioni-competenze-studenti/${certCompStudenteId}/pdf`;
    return this.http.get(url, { responseType: 'text' });
  }
}
