<app-header *ngIf="!hideHeader"></app-header>
<div id="main" [ngClass]="{'svolgi' : hideHeader}">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<div class="clear"></div>
<div class="loading-overlay" *ngIf="loading">
  <div class="wrap-spinner">
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
<app-svolgi-anteprima-prova id="svolgi-anteprima" [id]="provaId" [flag]="provaFlag"
  *ngIf="showProva"></app-svolgi-anteprima-prova>