import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TypeNotify } from '../../_model';
import { PercorsoService } from '../../_service';
import { SvolgiAnteprimaProvaService } from './svolgi-anteprima-prova.service';

@Component({
  selector: 'app-svolgi-anteprima-prova',
  templateUrl: './svolgi-prova.component.html',
  styleUrls: ['./svolgi-prova.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SvolgiProvaComponent implements OnInit {

  @Input() public flag?: string;
  @Input() public id?: string;
  public display: boolean = false;
  public info: any = { durata: 55, gradi: [3] };
  public width: number = 0;
  public height!: string;

  constructor(
    private svolgi: SvolgiAnteprimaProvaService,
    private percorsoService: PercorsoService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (this.flag == 'non-cognitivo') {
      this.height = window.innerHeight - 100  + 'px';
      let temp: any = document.getElementsByClassName('content-svolgi-prova')[0];
      temp.style.height = this.height;
    } else if (this.flag == 'cognitivo') {
      this.height = window.innerHeight - 80  + 'px';
      let temp: any = document.getElementsByClassName('content-svolgi-prova')[0];
      temp.style.height = this.height;
    } else {
      this.height = window.innerHeight - 100  + 'px';
      let temp: any = document.getElementsByClassName('content-svolgi-prova')[0];
      temp.style.height = this.height;
    }
  }

  ngOnInit(): void {
    const template = document.getElementById("svolgi-anteprima");
    document.body.appendChild(template!);
    document.body.classList.add('overflow');
    this.onWindowResize();
  }

  // UTILE IN FUTURO tag (scroll)="onWindowScroll($event)"
  onWindowScroll($event: any) {
    let winScroll = $event.target.scrollTop;
    var height = $event.target.scrollHeight - $event.target.clientHeight;
    var scrolled = (winScroll / height) * 100;
    this.width = scrolled;
  }

  hide() {
    document.body.classList.remove('overflow');
    switch (this.flag) {
      case 'non-cognitivo':
        this.percorsoService.subject.next(TypeNotify.assegnazioneNonCognitivo);
        break;
      case 'cognitivo':
        this.percorsoService.subject.next(TypeNotify.assegnazioneCognitivo);
        break;
      case 'modulo':
        this.percorsoService.subject.next(TypeNotify.assegnazioneModulo);
        break;
    }
    this.svolgi.hide();
  }

  getInfo($event: any) {
    this.info = $event;
    this.width = this.info.progress;
  }

  saved() {
    this.hide();
    setTimeout(() => window.location.reload(), 500);
  }
}
