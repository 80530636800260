import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetter'
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return '';
    let first = value.charAt(0).toUpperCase();
    return first + value.slice(1).toLocaleLowerCase();
  }
}
