import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestionariService } from '../../../_service';
import { Constants, MyToastrService } from '../../../_utility';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'app-svolgi-cognitivo',
  templateUrl: './svolgi-cognitivo.component.html',
  styleUrls: ['./svolgi-cognitivo.component.scss']
})
export class SvolgiCognitivoComponent implements OnInit {

  @Input() public id?: string;
  @Output() info = new EventEmitter<any>();
  @Output() saved = new EventEmitter<boolean>();

  public supportUrl: string= '';
  public height!: string;

  constructor(
    private sanitizer: DomSanitizer,
    private questionari: QuestionariService,
    private spinner: SpinnerService,
    private toastr: MyToastrService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.height = window.innerHeight - 198  + 'px';
  }

  ngOnInit(): void {
    this.spinner.show();
    this.onWindowResize();
    this.questionari.getQuestionarioStudente(this.id!).subscribe({
      next: (res: any) => {
        this.info.emit({ durata: res.minutiCompletamento, gradi: res.grado })
        this.spinner.hide();
        if (res.url && res.url.startsWith('https:')) {
          this.supportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.url) as string;
        } else {
          this.supportUrl = res.url;
          this.toastr.WARNING(Constants.TITLE_WARNING, 'Errore nel recupero del questionario');
          setTimeout(() => {
            this.saved.emit(false);
          }, 1000);
        }
      },
      error: (error) => {
        this.saved.emit(false);
        this.spinner.hide();
        this.toastr.WARNING(Constants.TITLE_WARNING, 'Errore nel recupero del questionario');
      }
    });
  }

  saveQuestionarioAndBack() {
    this.saved.emit(true);
  }

}
