export class Constants {

  public static DOCENTE_RADIX = 'docente';
  public static STUDENTE_RADIX = 'studente';

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Regex = class {
    public static EMAIL = "^[^(!#$%&*+\/=?^`{|}~)]*$";
  }

  static Routing = class {
    public static ACCESS_DENIED = { label: 'Access_denied', path: 'access_denied', routerLink: ['/access_denied'] };
    public static LOGIN = { label: 'Login', path: 'login', routerLink: ['/login'] };
    public static DASHBOARD = { label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard'] };
    public static PROFILO = { label: 'Profilo', path: 'profilo', routerLink: ['/profilo'] };
    public static CONTENUTI = { label: 'Contenuti', path: 'contenuti', routerLink: ['/contenuti'] };
    public static RISULTATI = { label: 'Risultati', path: 'risultati', routerLink: ['/risultati'] };
    public static RISULTATI_QUESTIONARI = { label: 'Risultati Questionari', path: 'risultati-questionari', routerLink: ['/risultati-questionari'] };
    public static SVOLGI_QUESTIONARIO = { label: 'Svolgi Questionario', path: 'svolgi-questionario', routerLink: ['/svolgi-questionario'] };
    public static ESPRIMI_PREFERENZE = { label: 'Esprimi le tue preferenze', path: 'esprimi-preferenze', routerLink: ['/esprimi-preferenze'] };
    public static RISULTATO_MODULO = { label: 'Risultato Modulo', path: 'risultato-modulo', routerLink: ['/risultato-modulo'] };
    public static COMPETENZE = { label: 'Scopri le tue competenze chiave', path: 'competenze', routerLink: ['/competenze'] };
  }

  static Auth = class {
    public static USER_KEY = "user";
    public static REMEMBER_KEY = "remember";
    public static ENV_KEY = "environment";
  }

  static Storager = class {
    public static USER_KEY = "user";
    public static MODULO = "modulo";
  }

  static QuestionariService = class {
    public static QUESTIONARI_ATTIVI = "/studenti/me/questionari-studenti";
  }

  static RisultatiService = class {
    public static RISULTATI_NON_COGNITIVI = "/studente/me/dettaglio/grafici";
  }

  static NotificheFirestoreService = class {
    public static BELL_PATH = (userId: string) => { return `/notifiche/${userId}/bell` };
  }

  static CarouselBreakpoint = class {
    public static PERCORSO = [
      {
        breakpoint: '1025px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '576px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  static Grafici = class {
    public static DEFAULT_CHART_OPT: any = {
      plugins: {
        legend: { display: false },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              return label;
            }
          },
          yAlign: 'bottom',
          xAlign: 'left',
          titleAlign: 'center',
          titleMarginBottom: 0
        }
      },
      scales: {
        r: {
          pointLabels: {
            display: false
          },
          ticks: {
            //display: false
            showLabelBackdrop: false,
            callback: function (value: any, index: any, ticks: any) {
              return '';
            }
          },
          angleLines: {
            color: '#CED6E2'
          },
          grid: {
            color: '#CED6E2',
          },
          min: 0,
          max: 5
          //beginAtZero: true
        }
      }
    };

    public static DEFAULT_RADAR_OPT = {
      backgroundColor: 'rgba(14, 180, 161, 0.5)',
      borderColor: '#0A8274',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#0A8274',
      pointHoverBackgroundColor: '#0A8274',
      pointHoverBorderColor: '#0A8274',
      pointRadius: 6,
      pointHoverRadius: 6,
      borderWidth: 1
    };
  }
}
