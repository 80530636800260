export class Studente {
    id?: string;
    nome?: string;
    cognome?: string;
    codiceInvito?: string;
    idInvito?: string;
    email?: string;
    provaCognitivaSvolta?: boolean;
    registrato?: boolean;
}
