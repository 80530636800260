import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomandeModuli, RisposteChiuse, TipologiaDomandaModulo } from '../../../_model';
import { QuestionariService } from '../../../_service';
import { Constants, MyToastrService } from '../../../_utility';
import { SpinnerService } from '../../spinner/spinner.service';
import { SvolgiAnteprimaProvaService } from '../svolgi-anteprima-prova.service';

@Component({
  selector: 'app-svolgi-modulo',
  templateUrl: './svolgi-modulo.component.html',
  styleUrls: ['./svolgi-modulo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SvolgiModuloComponent implements OnInit {

  @Input() public id?: string;
  @Output() info = new EventEmitter<any>();
  @Output() saved = new EventEmitter<boolean>();
  public datasource?: DomandeModuli;
  public tipologiaDomande = TipologiaDomandaModulo;

  constructor(
    private service: QuestionariService,
    private spinner: SpinnerService,
    private toastr: MyToastrService,
    private svolgi: SvolgiAnteprimaProvaService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.service.getPreviewModuliOrientativi(this.id!).subscribe({
      next: (res) => {
        this.info.emit({ durata: res.durata, gradi: res.gradi })
        this.datasource = res;
        this.spinner.hide();
      },
      error: (error) => {
        this.saved.emit(false);
        this.spinner.hide();
        this.svolgi.hide();
      }
    });
  }

  public checkDomande() {
    for (let domanda of this.datasource?.tracce!) {
      for (let risposta of domanda.elementi!) {
        if (risposta.tipologia === TipologiaDomandaModulo.radio) {
          for (let risposteChiuse of risposta.domande!) {
            if (!risposteChiuse.risposta) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public salvaModulo() {
    this.spinner.show();

    let body: DomandeModuli = this.datasource!;

    this.service.saveModuloOrientativo(this.id!, body).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.toastr.SUCCESS(Constants.TITLE_SUCCESS, 'Modulo salvato correttamente!');
        this.saved.emit(true);
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.ERROR(Constants.TITLE_ERROR, err.error.message);
      }
    });
  }

}
