<div id="svolgi-questionario">
  <div class="intestazione" *ngIf="currentPage == 0">
    <div class="d-flex justify-content-center align-items-center flex-wrap">
      <div class="testo">
        <h3>{{datasource?.nome}}</h3>
        <p>{{datasource?.introduzione}}</p>
      </div>
      <div class="image"></div>
    </div>
  </div>

  <form id="form-questionario" #myForm="ngForm">
    <p-paginator *ngIf="datasource && datasource.gruppi && datasource.gruppi.length > 1 ? true : false" #paginator
      (onPageChange)="paginate($event)" [styleClass]="'paginator-questionari'" [rows]="1"
      [totalRecords]="datasource && datasource.gruppi ? datasource.gruppi.length : 0" [showPageLinks]="false"
      [showFirstLastIcon]="false">
    </p-paginator>

    <ng-container *ngFor="let val of gruppiPaginate; let i = index;">
      <div class="disclaim">
        {{val.testo}}
      </div>
      <div class="questionario">
        <ng-container [ngSwitch]="val.tipologia">
          <ng-container *ngSwitchCase="tipologiaGruppi.tipologia2">
            <ng-container *ngTemplateOutlet="tipologia2; context: {quest:val,gruppo:val}"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="tipologiaGruppi.tipologia1">
            <ng-container *ngFor="let quest of val.gruppi">
              <div class="title">
                <h3>{{quest.testo | capitalizeFirstLetter}}</h3>
              </div>
              <ng-container [ngSwitch]="quest.tipologia">
                <ng-container *ngSwitchCase="tipologiaGruppi.tipologia2">
                  <ng-container *ngTemplateOutlet="tipologia2; context: {quest:quest,gruppo:val}"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #tipologia2 let-quest="quest" let-gruppo="gruppo">
      <ng-container *ngFor="let el of quest.domande">
        <div class="content-domanda" *ngIf="!el.hidden">
          <ng-container [ngSwitch]="el.tipologia">
            <ng-container *ngSwitchCase="'RADIO'">
              <ng-container
                *ngTemplateOutlet="radioButton; context: {gruppo:gruppo,domanda:el}"></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #radioButton let-domanda="domanda" let-gruppo="gruppo">
      <div class="d-sm-flex justify-content-sm-between align-items-center flex-wrap">
        <div class="domanda">{{domanda.testo}}</div>
        <div class="risposta">
          <div class="d-sm-flex justify-content-sm-between align-items-sm-end flex-wrap">
            <ng-container *ngFor="let risposta of domanda.risposte">
              <ng-container [ngSwitch]="datasource?.tipologia">
                <ng-container *ngSwitchCase="'FOLLOWUP'">
                  <!-- DATO CHE IL FOLLOW-UP HA DUE GRUPPI (PAGINE) DI DOMANDE, PAGINA 1 (INDEX 0) LE RISPOSTE ANDRANNO A CAPO -->
                  <!-- PAGINA 2 (INDEX 1) LE RISPOSTE SARANNO IN LINEA -->
                  <ng-container *ngIf="currentPage == 0">
                    <div class="form-group followup">
                      <p-radioButton required [name]="domanda.id + risposta.testo" [value]="risposta.valore"
                        [(ngModel)]="domanda.scelta" inputId="{{domanda.id + risposta.testo}}"
                        [styleClass]="'radio-quest'" (onClick)="valueChange($event,domanda, risposta.then,gruppo)">
                      </p-radioButton>
                      <label [for]="risposta.testo">{{risposta.testo}}</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="currentPage == 1">
                    <div class="form-group">
                      <label [for]="risposta.testo">{{risposta.testo}}</label>
                      <p-radioButton required [name]="domanda.id + risposta.testo" [value]="risposta.valore"
                        [(ngModel)]="domanda.scelta" inputId="{{domanda.id + risposta.testo}}"
                        [styleClass]="'radio-quest'" (onClick)="valueChange($event,domanda, risposta.then,gruppo)">
                      </p-radioButton>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="form-group">
                    <label [for]="risposta.testo">{{risposta.testo}}</label>
                    <p-radioButton required [name]="domanda.id + risposta.testo" [value]="risposta.valore"
                      [(ngModel)]="domanda.scelta" inputId="{{domanda.id + risposta.testo}}"
                      [styleClass]="'radio-quest'" (onClick)="valueChange($event,domanda)">
                    </p-radioButton>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>

    <p-button *ngIf="(currentPage+1) == totalPage" [disabled]="!validAllPage" (onClick)="save()"
      [style]="{'text-align': 'center', 'width': '100%','margin': '0 0 15px'}" label="Salva">
    </p-button>
  </form>
</div>