import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notifica, SvolgiProva, TypeNotify } from '../../_model';
import { AuthService, PercorsoService } from '../../_service';
import { NotificheFirestoreService } from '../../_service/notifiche-firestore.service';
import { Constants } from '../../_utility';
import { SvolgiAnteprimaProvaService } from '../svolgi-prova/svolgi-anteprima-prova.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  public newNotify: Array<Notifica> = [];
  public oldNotify: Array<Notifica> = [];
  private firstSearch: boolean = true;

  constructor(
    public auth: AuthService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    public notificheService: NotificheFirestoreService,
    public translate: TranslateService,
    private svolgi: SvolgiAnteprimaProvaService,
    public percorsoService: PercorsoService
  ) {
    this.viewportScroller.setOffset([0, 72]);
  }

  ngOnInit(): void {
    this.auth.authFinished$.subscribe({
      next: (finished: boolean) => {
        if (finished) {
          this.notificheService.newNotify.subscribe({
            next: (notifiche) => {
              let sortNotify = this.sortNotifiche(notifiche);
              if (!this.firstSearch) {
                let lastNotify = sortNotify[0];
                if (lastNotify)
                  this.percorsoService.subject.next(lastNotify.type!);
              } else {
                this.firstSearch = false;
              }
              this.setNotify(sortNotify, false);
            }
          });
          this.notificheService.oldNotify.subscribe({
            next: (notifiche) => this.setNotify(this.sortNotifiche(notifiche), true)
          });
        }
      }
    });
  }

  sortNotifiche(notifiche: Array<Notifica>): Array<Notifica> {
    return notifiche.sort((a, b) => {
      if (new Date(a.date!) > new Date(b.date!)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  setNotify(notifiche: Array<Notifica>, isRead: boolean) {
    if (isRead) {
      this.oldNotify = notifiche;
    } else {
      this.newNotify = notifiche;
    }
  }

  readNotify() {
    if (this.newNotify.length > 0) {
      this.notificheService.readNotify();
    }
  }

  goToNotify(notifica: Notifica) {
    switch (notifica.type) {
      case TypeNotify.assegnazioneNonCognitivo:
        let idQuestionario = notifica.params!['questionarioId'];
        let svolgiProvaQuestionario: SvolgiProva = new SvolgiProva(true, idQuestionario, 'non-cognitivo');
        this.svolgi.show(svolgiProvaQuestionario);
        break;
      case TypeNotify.assegnazioneCognitivo:
        let questionarioCognitivoId = notifica.params!['questionarioStudenteId'];
        let svolgiProvaQuestionarioCognitivo: SvolgiProva = new SvolgiProva(true, questionarioCognitivoId, 'cognitivo');
        this.svolgi.show(svolgiProvaQuestionarioCognitivo);
        break;
      case TypeNotify.assegnazioneModulo:
        let idModulo = notifica.params!['moduloStudenteId'];
        let svolgiProvaModulo: SvolgiProva = new SvolgiProva(true, idModulo, 'modulo');
        this.svolgi.show(svolgiProvaModulo);
        break;
      default:
        this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
        break;
    }
  }

  getIconByTypeNotify(type: TypeNotify) {
    let iconClass!: string;
    switch (type) {
      case TypeNotify.assegnazioneNonCognitivo:
        iconClass = 'icon-lamp-blue';
        break;
      case TypeNotify.assegnazioneCognitivo:
        iconClass = 'icon-questionnaire';
        break;
      case TypeNotify.assegnazioneModulo:
        iconClass = 'icon-compass';
        break;
      default:
        iconClass = 'icon-bell';
        break;
    }
    return iconClass;
  }

  goToDashboard() {
    this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
  }

  public scrollOnSection(elementId: string): void {
    if (this.router.url === 'dashboard') {
      this.viewportScroller.scrollToAnchor(elementId);
    } else {
      this.router.navigate(Constants.Routing.DASHBOARD.routerLink);
      setTimeout(() => { this.viewportScroller.scrollToAnchor(elementId); }, 500);
    }
  }

  async logout() {
    await this.auth.logout();
  }

}
