export class ProvaCognitiva {
    studentId?: string;
    esecuzioneId?: string; // ID MYEUTICAL
    punteggio?: number;
    punteggioMax?: number;
    dataConsegna?: Date;
    domande?: Array<Domanda>
}

export interface Domanda {
    tipo?: string;
    domanda?: string;
    risposta?: any;
    rispostaCorretta?: any;
    studentiRispostaCorretta?: number;
    studentiRispostaSbagliata?: number;
    consegnaId?: string;
    consegnaTesto?: string;
    consegnaTitolo?: string;
    corretta?: boolean;
    numDomande?: number;
    numRisposteCorrette?: number;
}
