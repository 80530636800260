import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RedirectLayoutComponent } from './_layout/redirect-layout/redirect-layout.component';
import { AuthGuard, Constants, RedirectGuard } from './_utility';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: Constants.Routing.DASHBOARD.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/dashboard/dashboard.module').then(mod => mod.DashboardModule) },
  { path: Constants.Routing.PROFILO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/profilo/profilo.module').then(mod => mod.ProfiloModule) },
  { path: Constants.Routing.CONTENUTI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/contenuti/contenuti.module').then(mod => mod.ContenutiModule) },
  { path: Constants.Routing.RISULTATI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/risultati/risultati.module').then(mod => mod.RisultatiModule) },
  { path: Constants.Routing.RISULTATI_QUESTIONARI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/risultati-questionari/risultati-questionari.module').then(mod => mod.RisultatiQuestionariModule) },
  { path: Constants.Routing.LOGIN.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data: { externalUrl: environment.futuri.redirect_url } },
  { path: Constants.Routing.ESPRIMI_PREFERENZE.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/esprimi-preferenze/esprimi-preferenze.module').then(mod => mod.EsprimiPreferenzeModule) },
  { path: Constants.Routing.RISULTATO_MODULO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/risultato-modulo/risultato-modulo.module').then(mod => mod.RisultatoModuloModule) },
  { path: Constants.Routing.COMPETENZE.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/competenze/competenze.module').then(mod => mod.CompetenzeModule) },
  { path: Constants.Routing.ACCESS_DENIED.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data: { externalUrl: environment.futuri.redirect_url + "/" + Constants.Routing.ACCESS_DENIED.path } },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
