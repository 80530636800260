import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ambito, PercorsoResponse, ScenariBanner, ScuolaTag, TypeNotify } from '../_model';

@Injectable({ providedIn: 'root' })
export class PercorsoService {
    public subject: Subject<TypeNotify> = new Subject<TypeNotify>();

    constructor(private http: HttpClient) { }

    getPercorso(id: string): Observable<PercorsoResponse> {
        let url = `${environment.host}/studenti/${id}/percorso`;
        return this.http.get<PercorsoResponse>(url);
    }

    newNotify(typeNotify: TypeNotify) {
        this.subject.next(typeNotify);
    }

    getScenariBanner(): Observable<ScenariBanner> {
        let url = `${environment.host}/studenti/me/scenari/banner`;
        return this.http.get<ScenariBanner>(url);
    }

    getScenariDiSuccesso(): Observable<ScuolaTag[]> {
        let url = `${environment.host}/studenti/me/scenari/successo`;
        return this.http.get<Array<ScuolaTag>>(url);
    }

    putScenariPreferenza(body: any) {
        let url = `${environment.host}/studenti/me/scenari/preferenze`;
        return this.http.put<any>(url, body);
    }

    getScuoleAmbiti(): Observable<Array<Ambito>> {
        let url = `${environment.host}/ambiti`;
        return this.http.get<Array<Ambito>>(url);
    }


    downloadPdf(certCompStudenteId: string) {
        let url = `${environment.host}/certificazioni-competenze-studenti/${certCompStudenteId}/pdf`;
        return this.http.get(url, { responseType: 'text' });
    }
}
