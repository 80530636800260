import { Authority, Type } from "..";
import { Classe } from "../Classe";

export interface User {
    [x: string]: any;
    //Property firebase
    uid?: string;
    displayName: string;
    emailVerified?: boolean;
    token: string;

    //Custom property
    cognome?: string;
    stato?: any;
    avatarUrl?: any;
    isTeacher?: boolean;
    isStudent?: boolean;
    isAdmin?: boolean;
    nicknameChanged?: any;

    id: string;
    email?: string;
    firebaseId: string;
    name?: string;
    username?: string;
    authorities?: Array<Authority>;

    // prop studente
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    credentialsNonExpired: boolean;
    dataNascita: string;
    studente: boolean;
    studenteId: string;
    classe: Classe;
    sesso: boolean;
    certCompStudId?: string;
}
