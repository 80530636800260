import { StatusQuestionarioStudente, TipologiaQuestionario } from ".";

export class Questionario {
  id?: string;
  dataStart?: string;
  dataDeadline?: string;
  descrizione?: string;
  grado?: Array<number>;
  minutiCompletamento?: number;
  dataConsegna?: string;
  idIstanzaStudMaieutical?: string;
  questionarioClasseId?: string;
  stato?: StatusQuestionarioStudente;
  studenteId?: string;
  url?: string;
  corsoNome?: string;
  questionarioNome?: string;
  studenteCognome?: string;
  studenteNome?: string;
  tipologia?: TipologiaQuestionario
}
