<div id="risultati-questionari-cognitivi" *ngIf="!!datasource && datasource.domande!.length > 0">
  <div class="content questionario">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="header-test">
            <div class="d-flex justify-content-between align-items-center">
              <div class="nome-studente">
                <h4>{{ datasource.questionarioNome }}</h4>
              </div>
              <div class="data-svolgimento">
                <span class="icon-data-svolgimento icon-file"></span>
                <span class="data">{{ 'questionari_cognitivi_studente.svolto' | translate }} <b>{{ datasource.dataConsegna }}</b></span>
              </div>
            </div>
          </div>
          <div class="content-test">
            <div class="banner-punteggio">
              <div class="punteggio">
                <span class="testo">{{ 'questionari_cognitivi_studente.punteggio' | translate }}</span>
                <span class="valore"><b style="font-size: 30px;">{{datasource.punteggio!}}/10</b></span>
              </div>
            </div>
            <ng-container *ngFor="let domanda of datasource.domande; let i = index">
              <div class="domanda">
                <p>{{(i+1) +') '}} <span>{{domanda.consegnaTitolo}}</span></p>
              </div>
              <div class="risposta">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <div class="left">
                    <h5>{{ 'questionari_cognitivi_studente.risposta' | translate }}</h5>
                    <ng-container *ngIf="!domanda.risposta.values || domanda.risposta.values.length == 0">
                      <div class="katex-value">
                        {{ 'questionari_cognitivi_studente.no_risposta' | translate }}
                      </div>
                    </ng-container>
                    <ng-container *ngFor="let risposta of domanda.risposta.values;">
                      <div *ngIf="getModelByDomanda(domanda, risposta)" class="katex-value">
                        <ng-katex-paragraph [paragraph]="getModelByDomanda(domanda, risposta)"></ng-katex-paragraph>
                      </div>
                      <div *ngIf="!getModelByDomanda(domanda, risposta)" class="katex-value">
                        {{ 'questionari_cognitivi_studente.no_risposta' | translate }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="right">
                    <span class="position-icon icon-confirm icon-exclamation-circle"
                      *ngIf="!domanda.rispostaCorretta"></span>
                    <span class="position-icon icon icon-check-circle" *ngIf="domanda.rispostaCorretta"></span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>