import { StatusModuloStudente } from '.';

export class ModuloOrientativo {
  id?: string;
  grado?: number;
  dataDeadline?: string;
  dataStart?: string;
  data?: Date;
  minuti?: string;
  stato?: StatusModuloStudente;
  moduloId?: string;
  moduloStudenteId?: string;
  nomeMacroModulo?: string;
  nomeMateria?: string;
  nomeModulo?: string;
  macroModuloImage?: string;
  materiaNome?: string;
  moduloClasseId?: string | null;
  moduloDiarioId?: string | null;
  moduloNome?: string;
  moduloValutazioneId?: string;
  studenteId?: string;
  studenteNome?: string;
  studenteCognome?: string;
}
