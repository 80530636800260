export class PercorsoResponse {
    studenteId?: string;
    gradoClasse?: number;
    testNonCognitivo?: boolean;
    contenutiScolastici?: boolean;
    testCognitivo?: boolean;
    moduloCompleto?: boolean;
    scenariSuccesso?: boolean;
    istitutiPreferiti?: boolean;
    scuolaSuperioreScelta?: boolean;
    scuolaSuperioreConferma?: boolean;
    avanzamento?: number;
}