import { TipologiaContenuto } from "./enum/TipologiaContenuto";


export class FiltroContenuti {
  q?: string;
  tipo?: TipologiaContenuto;
  tagPadreId?: string;
  tagFigliIds?: Array<string>;

  constructor() { }
}
