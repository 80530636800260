import { Studente } from ".";

export class QuestionarioCognitivoRisposteResponse<T> {
    public questionarioNome?: string;
    public punteggio?: number;
    public studentId?: string;
    public dataConsegna?: string;
    public esecuzioneId?: string; // ID MYEUTICAL
    public domande?: Array<T>;
}
