export class Classe {
    annoScolastico?: number;
    codiceClasse?: string;
    completamento?: number;
    docenteReferenteId?: string;
    grado?: number;
    id?: string;
    numeroStudenti?: number;
    propostaReferente?: any;
    propostaReferenteCognome?: string;
    propostaReferenteNome?: string;
    referente?: any;
    sezione?: string;
}