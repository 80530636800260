<div id="svolgi-prova">
  <header id="header-svolgi">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="back-title">
            <div class="d-flex justify-content-start align-items-center">
              <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-rounded p-button-text"
                (click)="hide()"></button>
              <ng-container [ngSwitch]="flag">
                <h3 class="title-svolgi" *ngSwitchCase="'non-cognitivo'">Completa il questionario</h3>
                <h3 class="title-svolgi" *ngSwitchCase="'modulo'">Completa il moodulo</h3>
                <h3 class="title-svolgi" *ngSwitchCase="'risultati-questionario'">Risultati questionario</h3>
                <h3 class="title-svolgi" *ngSwitchCase="'cognitivo'">Completa la prova</h3>
                <h3 class="title-svolgi" *ngSwitchDefault>Anteprima</h3>
              </ng-container>
            </div>
          </div>
          <div class="save-info">
            <div class="d-flex justify-content-start align-items-center">
              <div class="durata-info">
                <div class="d-flex justify-content-start align-items-center">
                  <ng-container *ngIf="flag !== 'risultati-questionario'">
                    <div class="durata">Durata media <span><i class="icon icon-stopwatch"></i> {{info.durata}}m</span>
                    </div>
                    <div class="separator"></div>
                    <div class="info">Per classi <span class="grado" *ngFor="let el of info.gradi">{{el}}°</span></div>
                  </ng-container>
                </div>
              </div>
              <!-- <button class="salva" pButton pRipple type="button" label="Salva"></button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="progress-container">
    <div class="progress-bar" id="myBar" [style.width.%]="width"></div>
  </div>

  <section #sectionScoll class="content-svolgi-prova"
    [ngClass]="{'quest-non-cognitivo': flag === 'non-cognitivo', 'quest-cognitivo': flag === 'cognitivo', 'quest-modulo': flag === 'modulo' }">
    <div class="container">
      <div class="row">
        <div class="col">
          <ng-container [ngSwitch]="flag">
            <app-svolgi-questionario [id]="id" *ngSwitchCase="'non-cognitivo'" (saved)="saved()"
              (info)="getInfo($event);sectionScoll.scrollTop=0"></app-svolgi-questionario>
            <app-svolgi-modulo [id]="id" *ngSwitchCase="'modulo'" (saved)="saved()" (info)="getInfo($event)">
            </app-svolgi-modulo>
            <app-risultati-questionari-cognitivi (saved)="saved()" [id]="id" *ngSwitchCase="'risultati-questionario'">
            </app-risultati-questionari-cognitivi>
            <app-svolgi-cognitivo [id]="id" *ngSwitchCase="'cognitivo'" (saved)="saved()"
              (info)="getInfo($event)"></app-svolgi-cognitivo>
            <p *ngSwitchDefault>prova tutto quello che vuoi</p>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>