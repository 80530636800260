import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Classe, ResponseGrafici, ResponseRisultatiQuestionari } from '../_model';
import { Constants } from '../_utility';

@Injectable({
  providedIn: 'root'
})
export class RisultatiService {

  constructor(private http: HttpClient) { }

  getRisultatiNonCognitivi(anno: number, esagono: boolean): Observable<ResponseGrafici> {
    let url = `${environment.host}${Constants.RisultatiService.RISULTATI_NON_COGNITIVI}`;
    return this.http.get<ResponseGrafici>(url, { params: { 'anno': anno, 'esagono': esagono } });
  }

  getRisulatiQuestionari(anno: number): Observable<ResponseRisultatiQuestionari> {
    let url = `${environment.host}/studente/me/dettaglio/questionari-cognitivi?anno=${anno}`;
    return this.http.get<ResponseRisultatiQuestionari>(url);
  }

  getClassi(): Observable<Array<Classe>> {
    let url = `${environment.host}/studenti/me/classi`;
    return this.http.get<Array<Classe>>(url);
  }

}
