export class SvolgiProva {
    show: boolean;
    id: string;
    flag: string;

    constructor(show: boolean, id: string, flag: string) {
        this.show = show;
        this.id = id;
        this.flag = flag;
    }
}