import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../_service';
import { Constants } from '../Constants';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const token = this.authService.token;
        const remember = this.authService.remember;

        if (!this.authService.checkEnvironmentCookie) {
            this.authService.logout();
        }

        if (!token && remember) {
            return this.authService.getCustomTokenFromCookie();
        }
        
        if (token) {
            let isStudent = this.authService.user.isStudent;
            if (!isStudent) {
                this.authService.logout(false);
                this.router.navigate(Constants.Routing.ACCESS_DENIED.routerLink);
                return false;
            }
            // logged in so return true
            return true;
        }

        this.authService.logout();
        return false;
    }
}
